<template>
  <div id="app">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="Лаборатона работа №1" name="1">
        <template slot="title">
          <h1>Лабораторная работа №1</h1>
        </template>

        <div class="mb-40">
          <p class="no-margin">Требуется реализовать компонент на чистом HTML, CSS, JavaScript.</p>
          <p class="no-margin">Код рекомендуется писать в одном файле <i>index.html</i>.</p>
          <p class="no-margin">Использовать любые библиотеки запрещено. Разрешено только использование библиотек для иконок.</p>
          <p><b>Критерии:</b></p>
          <p class="no-margin"><b>1.</b> Визульное и логическое соответствие. Корректный вид, рабочая логика.</p>
          <p class="no-margin"><b>2.</b> Использование тегов и стилей по прямому назначению. Стилизация по BEM.</p>
          <p class="no-margin"><b>3.</b> Универсальность компонента. Структура компонента должна быть разработана достаточно универсально и чисто.</p>
          <p class="no-margin">Например, для варианта 2 должно быть возможно изменить одно число 5 на 6. Соответственно, рейтинг становится рабочим для 6 звезд. Избегать копипаста. Одна точка входа.</p>
        </div>
        <div>
          <h1>Варианты:</h1>
        </div>
        <div id="task1" class="mb-20">
          <h2>1. Переключатели</h2>
          <div class="mb-20">
            <el-switch
              v-model="value1"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
          <div>
            <el-checkbox v-model="value1">Переключатель</el-checkbox>
          </div>
        </div>

        <div class="mb-20">
          <h2>2. Оценка</h2>
          <el-rate v-model="value2"></el-rate>
        </div>

        <div class="mb-20">
          <h2>3. Прогресс</h2>
          <div style="max-width: 400px">
            <el-progress :percentage="value3" :color="`hsl(${value3}, 100%, 50%)`"></el-progress>
            <button @click="value3 -= value3 > 0 ? 10 : 0">-10</button>
            <button @click="value3 += value3 < 100 ? 10 : 0">+10</button>
          </div>
        </div>

        <div class="mb-20">
          <h2>4. Уведомление</h2>
          <button @click="open4">Открыть</button>
        </div>

        <div class="mb-20">
          <h2>5. Вкладки</h2>
          <div style="max-width: 400px">
            <el-tabs type="border-card">
              <el-tab-pane label="Профиль">Профиль</el-tab-pane>
              <el-tab-pane label="Избранное">Избранное</el-tab-pane>
              <el-tab-pane label="Настройки">Настройки</el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="mb-20">
          <h2>6. Выпадающий список</h2>
          <div style="max-width: 400px">
            <el-select v-model="value6" placeholder="Выбрать">
              <el-option
                v-for="item in optionsForSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="mb-20">
          <h2>7. Модальное окно</h2>
          <div style="max-width: 400px">
            <button @click="value7 = true">Открыть</button>
            <el-dialog
              title="Заголовок"
              :visible.sync="value7"
              width="30%"
              :before-close="() => { value7 = false }">
              <span>Модальное окно</span>
              <span slot="footer" class="dialog-footer">
                <button @click="value7 = false">Ок</button>
              </span>
            </el-dialog>
          </div>
        </div>

        <div class="mb-20">
          <h2>8. Аккордеон</h2>
          <div style="max-width: 400px">
            <el-collapse v-model="value8">
              <el-collapse-item title="Заголовок1" name="1">
                <div>Текст 1</div>
              </el-collapse-item>
              <el-collapse-item title="Заголовок2" name="2">
                <div>Текст 2</div>
              </el-collapse-item>
              <el-collapse-item title="Заголовок3" name="3">
                <div>Текст 3</div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>

        <div class="mb-20">
          <h2>9. Лоадер</h2>
          <button @click="startLoader">Загружать 5 секунд</button>
        </div>

        <div class="mb-20">
          <h2>10. Числовой инпут</h2>
          <div style="max-width: 400px">
            <el-input-number v-model="value10" :precision="2" :step="0.1" :min="value10min" :max="value10max"></el-input-number>
            <br>
            <br>
            <div>
              min: <el-input-number v-model="value10min"></el-input-number>
            </div>
            <br>
            <div>
              max: <el-input-number v-model="value10max"></el-input-number>
            </div>
          </div>
        </div>
      </el-collapse-item>

      <el-collapse-item title="Лаборатона работа №2" name="2">
        <template slot="title">
          <h1>Лабораторная работа №2</h1>
        </template>

        <div>
          <p class="no-margin">Требуется реализовать компонент на любом фреймворке (Vue, React, Angular).</p>
          <p class="no-margin">Использовать любые библиотеки, кроме прямых зависимостей фреймворка, запрещено. Сторонние иконки разрешены.</p>
          <p><b>Критерии:</b></p>
          <p class="no-margin"><b>1.</b> Визульное и логическое соответствие. Корректный вид, рабочая логика.</p>
          <p class="no-margin"><b>2.</b> Использование тегов и стилей по прямому назначению. Стилизация по BEM. Использование препроцессоров. Стиль кода соответствует требованиям.</p>
          <p class="no-margin"><b>3.</b> Универсальность компонента. Структура компонента должна быть разработана достаточно универсально и чисто. Использовать логику фреймворка. Возможность использования компонента в любом месте приложения.</p>
          <p class="no-margin"><b>4.</b> Документация и описание компонента: props, emit events. Примеры использования с кодом и картинками результатов. Оформить в README.MD.</p>
          <p class="no-margin"><b>5.</b> <b>(Для тех, кто выбрал собственный фреймворк, а не Vue 2)</b> Сделать форк <a href="https://github.com/reznikovkg/just-copy" target="_blank">репозитория</a>. По соответствию с примером для Vue проекта создать каталог проекта и компонентов вашего фреймворка. Сделать Pull Request в <a href="https://github.com/reznikovkg/just-copy" target="_blank">репозиторий</a>.</p>
          <p><b>Вариант соответствует лабораторной работе №1</b></p>
        </div>
        <div>
        </div>
      </el-collapse-item>

      <el-collapse-item title="Лаборатона работа №3" name="3">
        <template slot="title">
          <h1>Лабораторная работа №3</h1>
        </template>

        <div>
          <p class="no-margin">Требуется интегрировать разработанный компонент лабораторной работы №2 в <a href="https://github.com/reznikovkg/just-copy" target="_blank">репозиторий</a>.</p>
          <p class="no-margin">Предварительно, необходимо сделать fork оригинального репозитория к себе на учетную запись в GitHub.</p>
          <p class="no-margin">Необходимо создать новую страницу в приложении и модуль хранилища.</p>
          <p class="no-margin">На новой, соответствующей вашему компоненту, странице разместить собственный компонент и примеры использования.</p>
          <p class="no-margin">Данные для работы с компонентом использовать в компоненте страницы через хранилище.</p>
          <p class="no-margin">Документацию разместить в корне каталога компонента (README.MD).</p>
          <p><b>Критерии:</b></p>
          <p class="no-margin"><b>1.</b> Корректный вид, рабочая логика. Страница и компонент отвечают требованиям. Структура каталогов и файлов соблюдена.</p>
          <p class="no-margin"><b>2.</b> Стиль кода, параметры и прочие свойства представлены корректно (HTML, CSS (и препроцессинг), JS (и логика фреймфорка)). Компонент универсален. Документация отображает компонент и способы его использования.</p>
          <p class="no-margin"><b>3.</b> Использование и верное применение модулям хранилища.</p>
          <p class="no-margin"><b>4.</b> Корректное использование git. Оформление, коммиты, ревью. Будут назначены по 2-3 PR коллег на ревью.</p>
          <p class="no-margin"><b>5.</b> <b>(Для тех, кто выбрал собственный фреймворк, а не Vue 2) На выбор 5.1 или 5.2</b></p>
          <p class="no-margin"><b>5.1.</b> Реализовать еще один компонент из варианта <b>N MOD 10 + 1</b>, где N - вариант из лабораторной работы №1.</p>
          <p class="no-margin"><b>5.1.</b> Добавить заготовку на фрейворке Express (NodeJS) (Либо собственный с подробным описанием использования). Демонстрация GET, POST, PATCH, PUT, DELETE запросов. Подключение к базе данных SQLite и модуля Sequelize.</p>
        </div>
        <div>
          <p>
            <b>Вариант соответствует лабораторной работе №1</b><br>
            <b>Лабораторная работа считается засчитанной, если Pull Request был одобрен или отклонен с комментарием о сдачи.</b>
          </p>
        </div>
      </el-collapse-item>

      <el-collapse-item title="Лаборатона работа №4" name="4">
        <template slot="title">
          <h1>Лабораторная работа №4</h1>
        </template>

        <div>
          <p class="no-margin">Требуется реализовать связь между веб-приложением и сервером (Express, либо собственный).</p>
          <p class="no-margin">Развернуть сервер и добавить собственные эндпоинты. Добавить в хранилище экшены запросов на сервер.</p>
          <p class="no-margin">Реализовать рендеринг своего комопнента из соответствующего варианта по полученным данным с сервера. Компоненты должны быть независимы.</p>
          <p><b>Критерии:</b></p>
          <p class="no-margin"><b>1.</b> Корректный вид, рабочая логика. Страница и компонент отвечают требованиям. Структура каталогов и файлов соблюдена.</p>
          <p class="no-margin"><b>2.</b> Развернут бекенд. Корректно использованы запросы к серверу и выдача ответов.</p>
          <p class="no-margin"><b>3.</b> Корректный рендеринг компонентов по результатам ответа сервета и независимость между компонентами.</p>
          <p class="no-margin"><b>4.</b> <b>(Для тех, кто выбрал собственный фреймворк, а не Vue 2)</b> Реализация и использование своего компонента в связке с бекендом. Создание, чтение, редактирование и удаление в БД. Методы GET, POST, PUT, DELETE.</p>
        </div>
        <div>
          <p>
            <b>Вариант соответствует лабораторной работе №1</b><br>
            <b>Лабораторная работа считается засчитанной, если Pull Request был одобрен или отклонен с комментарием о сдачи.</b>
          </p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      value1: true,
      value2: 0,
      value3: 10,
      value6: 1,
      value7: false,
      value8: [],
      value10: 0,
      value10min: -10,
      value10max: 10,
    }
  },
  computed: {
    optionsForSelect () {
      return [{
          value: 1,
          label: 'Завтрак'
        }, {
          value: 2,
          label: 'Обед'
        }, {
          value: 3,
          label: 'Ужин'
        }]
    }
  },
  mounted () {

  },
  methods: {
    open4() {
      this.$notify({
        title: 'Заголовок',
        message: 'Описание',
      });
    },
    startLoader () {
        const loading = this.$loading({
          lock: true,
          text: 'Загрузка',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 5000);
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  max-height: 100vh;
  padding: 20px;
  overflow-y: scroll;
  box-sizing: border-box;
}
.no-margin {
  margin: 0;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}
.carusel-item {
  background-color: rgb(28, 202, 255);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 100px;
}
.el-carousel__indicator {
  width: 20px;
  height: 20px;
  overflow: hidden;
  background: white;
  padding: 2px;
  border-radius: 50%;
}
</style>
